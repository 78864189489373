// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming


@use '@angular/material' as mat;
// Plus imports for other components in your app.


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$montagespezis-primary: mat.define-palette(mat.$indigo-palette);
$montagespezis-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$montagespezis-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$montagespezis-theme: mat.define-light-theme((
  color: (
    primary: $montagespezis-primary,
    accent: $montagespezis-accent,
    warn: $montagespezis-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($montagespezis-theme);



@font-face {
  font-family: "Myriad-Pro";
  src: local("Myriad-Pro"), url(assets/fonts/Myriad-Pro.woff2) format("woff2");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NeuropolXRg";
  src: local("NeuropolXRg"), url(assets/fonts/NeuropolXRg-Regular.woff2) format("woff2");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
