$primary: #62B7DF;
$primary-text: #3B3C3C;
$border-color: #D3D3D3;


.empty-informationBox {
  background-color: grey;
  width: 100%;
  border: $border-color 0.05em solid;
  border-radius: 1em;
  padding: 0.1vw;
  padding-top: 0 !important;
  -moz-transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
}


body {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 1.8;
  //color: #929292;
  color: $primary-text;
  overflow-wrap: break-word;
  word-wrap: break-word;

}


* {
  font-family: "Myriad-Pro";
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  background: none;
  border: none;
  cursor: pointer;


  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.icon-normal {
  font-size: 1.5em;
}

.icon-grey {
  color: #939393;
}

.icon-white {
  color: #fff;
}

.icon-big {
  font-size: 2em;
}

.icon-blue {
  color: darken(#62B7DF, 30%) !important;
}


body {
  margin: 0;
  //background-color: #65b7e7;
  font: 12px "Myriad-Pro", sans-serif;
}


.container {
  width: 80%;
  margin: 0 auto;
}

header {
  position: relative;
  z-index: 1;
  padding: 1em 0;

  height: 5em;

  a {
    color: $primary-text;
    text-decoration: none;
  }

  a.logo {
    font-weight: bold;

    &:hover {
      color: darken($primary, $amount: 50%);
      background: darken($primary, 10%);
    }
  }

  nav {
    float: right;

    ul {
      list-style-type: none;
      margin: 0;
      display: flex;

      li a {
        padding: 1em;

        &:hover {
          //animation: navHoverAnim 0.5s alternate linear;
          border-bottom: #62B7DF solid 0.125em;

          text-decoration: none !important;
        }

      }

    }
  }

}

label {
  display: flex;
}

html, body {
  height: 100%;
}

h1, h2, h3, h4 {
  font-family: "NeuropolXRg";
  white-space: nowrap;
}

h3 {
  color: $primary-text;
  font-weight: 500 !important;
}

h4 {
  font-size: 2em !important;
  color: rgb(98, 183, 223);
  font-family: "NeuropolXRg", sans-serif !important;
}

h5, h6 {
  font-family: Roboto, serif;
  margin: 0.75em 0;
}

h5 {
  font-size: 125% !important;
  font-weight: bold !important;
}

h6 {
  font-size: 115% !important;
}


.search-field {
  border: 0.125em solid lighten($primary-text, 50%) !important;
  padding: 0 0.75em !important;
  border-radius: 1.5em;
}

input {
  width: 100%;
}

input[type=text], input[type=password], input[type=number], textarea {
  outline: 0;
  padding: 0.5em;
}

textarea {
  border: lighten($primary-text, 50%) solid 0.125em !important;
}

textarea:focus, .search-field:focus {
  border-color: $primary !important;
}

input[type=text], input[type=password], input[type=number] {
  border: none;
  border-bottom: lighten($primary-text, 50%) solid 0.125em;
}

input[type=text]:focus, input[type=password]:focus, input[type=number]:focus {
  border-bottom-color: $primary !important;
}

p {
  margin-bottom: 0.3rem !important;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  cursor: pointer;
}

input[type="checkbox"] + label:before {
  content: '';
  width: 1em;
  height: 1em;
  border: 0.1em solid $primary;
  position: absolute;
  opacity: 0.6;
}

input[type="checkbox"]:checked + label:before {
  width: 0.5em;
  left: 0.25em;
  border-radius: 0;
  opacity: 1;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
}


textarea {
  height: auto;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  line-height: 2em;
  border: none;
  -webkit-transition: height 2s ease;
  -moz-transition: height 2s ease;
  -ms-transition: height 2s ease;
  -o-transition: height 2s ease;
  transition: height 2s ease;
}

.small-information-box {
  background-color: white;
  border-radius: 1em;
  padding: 1em !important;
  color: $primary-text;
  width: 100%;
  cursor: default;

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  td {
    padding-top: 0.5em;
    padding-left: 1em;
  }
}

.show-more {
  border: none;
  border-radius: 0.125em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: $primary;
  color: white;
}

.project-create-button {
  width: 70px;
  height: 70px;
  background-color: #3B3C3C;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 #666;

  cursor: pointer;
  font-size: 50px;
  color: white;
  text-align: center;
  line-height: 70px;

  position: fixed;
  right: 50px;
  bottom: 50px;
}

.delete-button {
  margin-right: 1em;
  border: none;
  border-radius: 0.25em;
  color: $primary;
  cursor: pointer;
  background-color: unset;
}

.blue {
  color: #62B7DF;
  background-color: #eff8fd;
}

// SUMMARY/DETAILS STUFF ------------------------------------------------

summary {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  outline: none;
  font-size: 1.25em;
}

.inline-box {
  display: flex;
  justify-content: space-between;

  .wrap {
    flex-wrap: wrap;
  }
}

.align-mid-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

.summary-box {
  border-radius: 1em !important;
  cursor: pointer;
  margin: 1em;


  summary {
    padding: 1em;
  }

  .inline-box {
    margin: 1em;
  }

  .summary-content-box {
    padding: 1em;

  }

  .heading {
    font-weight: bold;
    font-size: 1.25em;

    span {
      margin-right: 0.5em;
    }
  }

  .delete-button {
    line-height: 1.25em;
  }

  .update-button {
    line-height: 1.25em;
  }

}

// Main Stuff ------------------------------------------------

.window-box {
  padding: 1em;
  margin: 1em;
  box-shadow: 13px 13px 26px #d5d9d6,
  -13px -13px 26px #f2f2f2;
  background-color: white;
  border-radius: 1em;
}

.box {
  border-radius: 1em;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
}

.box-no-flex {
  border-radius: 1em;
  padding: 0.5em 1em;
}

.dark-I {
  color: #3b3c3c;
  background: #c1c9cd;
}

.dark-II {
  color: #3b3c3c;
  background: darken(#eff8fd, 15%);
}

.dark-III {
  color: #3B3C3C;
  background: darken(#eff8fd, 20%);
}

.div-scrollable {
  border-radius: 1em;
  width: 100%;
  max-height: calc(100vh - (75px + 50px + 2em + 119px + 10px));
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0.5em;
}

.modal-title {
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

// WeekPlan create/update stuff ----------------------------------

.week-plan-update {
  .jn-button {
    margin-top: 0.25em;
    border-radius: 0.25em;
    border: unset;
    padding-left: 1em;
    padding-right: 1em;
    color: white;

  }

  .template-box {
    padding: .5em;
  }

  .checkbox-frame {
    display: flex;
    justify-content: space-between;

    p {
      width: calc(100% - 24px);
    }

    label {
      height: .5em;
    }

    .checkbox-container {
      width: 24px;
      text-align: center;
    }

  }

  .content-div {
    margin: 0.5em 0;
  }

  .input-autocomplete-width {
    width: calc(100% - 1rem);
  }
}

// Template Stuff ------------------------------------------------

.template-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.template-box {

  margin-right: 0.3em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  border-radius: 1em;
  padding-left: 0.5em;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .2s;

  .template-text {
    margin-right: 0.5em;
    min-width: 100px;
  }

  p {
    margin: auto;
  }


}

.template-box-no-flex {

  border-radius: 1em;
  padding-left: 0.5em;
  text-align: center;
  transition: all .2s;

  .template-text {
    margin-right: 0.5em;
    min-width: 100px;
  }

  p {
    margin: auto;
  }
}

.template-inactive {
  border: 2px #62B7DF solid;
  border-radius: 1em;

  background-color: #fff;
  color: darken($primary, 10%);
}

.template-active {
  background-color: green;
  border: solid green 4px;
  border-radius: 1em;

  color: white;
}

.template-container {
  display: flex;
  flex-direction: row;

  .template-div {
    border: 2px $primary solid;
    display: flex;
    color: darken($primary, 10%);
    padding: 0.5em;
    margin: 0.1em;
  }

  .template-border-right {
    border-right: 1px solid $primary;
  }

  .number-div {
    margin-right: 0.3em;
    border-radius: 1em;
    background-color: $primary;
    color: white;
  }
}

// Animation Stuff ------------------------------------------------

.hover-animation {
  transition: all .2s;
}

.hover-animation:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 100, 0.2);
}

// Button ------------------------------------------------

.clickable-object {
  cursor: pointer;
}

.button-box {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.5em
}

.non-clickable-object {
  cursor: default;
}

.button,
.button:link,
.button:visited {
  text-align: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 0.5em !important;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1em 2em !important;
  display: inline-block;
  transition: all .2s;
  position: relative;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 100, 0.2);
}

.button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 100, 0.2)
}

.button::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
}

.button:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.button:disabled,
.button[disabled] {
  background-color: darken(#3B3C3C73, 0.2) !important;
  color: black !important;
  Cursor: text !important;
  pointer-events: none;
}

.button-grey {
  background-color: #3B3C3C73;
  color: white;
}

.button-blue {
  background-color: $primary;
  color: white;
}

.button-green {
  background-color: green;
  color: white;
}

.error-message {
  border-radius: 5px;
  background-color: rgba(255, 0, 0);
  color: white;
  padding: 5px;
  margin: 10px;
  text-align: center;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


// LEAVE IT ALONE!!!!!!!
.cdk-overlay-container {
  z-index: 3000;
}

// LEAVE IT ALONE!!!!!!!
